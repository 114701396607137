import cn from 'classnames';

import styles from './Helpers.module.scss';

type Props = React.ComponentPropsWithoutRef<'div'> & { children: React.ReactNode };

export const desktopOnly = styles.isDesktop;
export const mobileOnly = styles.isMobile;
export const notMobileOnly = styles.isNotMobile;

export enum Screen {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export const DesktopOnly = ({ children, className, ...props }: Props) => (
  <div className={cn(styles.isDesktop, className)} {...props}>
    {children}
  </div>
);

export const MobileOnly = ({ children, className, ...props }: Props) => (
  <div className={cn(styles.isMobile, className)} {...props}>
    {children}
  </div>
);

type WithLoaderProps = {
  children: React.ReactNode;
  fallback: React.ReactNode;
  isLoading: boolean;
};

export const WithLoader = ({ children, fallback, isLoading }: WithLoaderProps) => {
  if (isLoading) {
    return fallback;
  }
  return children;
};
