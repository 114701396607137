import { getAmountPerVoyage, getAmountTaxAware } from '@/helpers/data/mappers/amount';
import { FiltersPriceType, FiltersSortType } from '@/infra/types/common/filters';
import { type SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

export type TSorter<T extends Record<string, unknown>> = keyof T | ((value: T) => number | string | boolean);

export const getSailingPrice = (sailing: SailingFromPackages, priceType: FiltersPriceType, sailors: number) =>
  priceType === FiltersPriceType.sailorPerNight
    ? getAmountPerVoyage(sailing.startingPrice, sailing.duration, { sailors })
    : getAmountTaxAware(sailing.startingPrice);

export const makePackageSailingSorters = (
  sortType: FiltersSortType,
  priceType: FiltersPriceType,
  sailors: number,
): TSorter<SailingFromPackages>[] => {
  const byDate = 'startDate';
  const byDuration = 'duration';
  const byDurationDesc = (sailing: SailingFromPackages) => -sailing.duration;
  const byPrice = (sailing: SailingFromPackages) => getSailingPrice(sailing, priceType, sailors);
  const byPriceDesc = (sailing: SailingFromPackages) => -getSailingPrice(sailing, priceType, sailors);

  switch (sortType) {
    case FiltersSortType.date:
      return [byDate, byPrice];
    case FiltersSortType.durationAsc:
      return [byDuration, byPrice];
    case FiltersSortType.durationDesc:
      return [byDurationDesc, byPrice];
    case FiltersSortType.priceAsc:
      return [byPrice, byDate];
    case FiltersSortType.priceDesc:
      return [byPriceDesc, byDate];
    case FiltersSortType.recommended:
    default:
      return [];
  }
};
