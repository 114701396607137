'use client';

import Image from 'next/image';
import { forwardRef } from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { useIntersectionObserver } from 'usehooks-ts';

import Anchor from '@publicImages/svg/anchor.svg?icon';
import Location from '@publicImages/svg/location.svg?icon';
import Ship from '@publicImages/svg/ship.svg?icon';

import type { Package } from '@/infra/types/voyageInfo/package';

import UIResource from '@/components/UIResource';
import WithFrenchContent from '@/components/WithFrenchContent';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { formatDate } from '@/helpers/util/dateUtil';
import { formatHumanReadableList } from '@/helpers/util/misc';
import { useAppSelector } from '@/store';

import styles from './ViewMoreDates.module.scss';

export const HeaderWithImage = forwardRef<HTMLHeadingElement, { id?: string; packageData: Package }>(
  ({ id, packageData, ...props }, ref) => {
    const { landscapeHero, packageName } = packageData;

    const [titleRef, isTitleVisible] = useIntersectionObserver({ initialIsIntersecting: true });
    const isHeaderSticky = !isTitleVisible;

    return (
      <>
        <div className={styles.headerImage}>
          <Image alt={landscapeHero?.alt} fill src={landscapeHero?.src} />
        </div>
        <div className={cn(styles.titleWithImage, isHeaderSticky && styles.hide)} ref={titleRef}>
          <WithFrenchContent className={styles.content} id={id} node="h2" text={packageName} />
        </div>
        <div aria-hidden className={cn(styles.titleWithImageSticky, isHeaderSticky && styles.show)}>
          <h2 className={styles.content} ref={ref} {...props}>
            {packageName}
          </h2>
        </div>
      </>
    );
  },
);
HeaderWithImage.displayName = 'HeaderWithImage';

export const Header = () => {
  const [titleRef, isTitleVisible] = useIntersectionObserver({ initialIsIntersecting: true, threshold: 0.5 });
  const isHeaderSticky = !isTitleVisible;

  return (
    <>
      <div className={cn(styles.title, isHeaderSticky && styles.hide)} ref={titleRef}>
        <UIResource id="DatesModule.heading" />
      </div>
      <div aria-hidden className={cn(styles.title, styles.titleSticky, isHeaderSticky && styles.show)}>
        <UIResource id="DatesModule.heading" />
      </div>
    </>
  );
};

export const PackageDescription = forwardRef<HTMLDivElement, { packageData: Package } & React.ComponentProps<'div'>>(
  ({ packageData, ...props }, ref) => (
    <div className={styles.description} {...props} ref={ref}>
      <div>
        <Ship />
        <span>
          <UIResource
            id="itineraryOverlay.nightsShipName"
            values={{
              N: packageData.duration ?? 5,
              shipName: formatHumanReadableList(packageData.sailingList?.map((e) => e.ship.name)),
            }}
          />
        </span>
      </div>
      <div>
        <Location />
        <span>
          <UIResource
            id="itineraryOverlay.tripTypePort.text"
            values={{
              country: get(packageData, 'sailingList.0.ports.0.countryName', ''), // Need to change correctly
              departurePort: get(packageData, 'sailingList.0.ports.0.name', ''),
              tripType: get(packageData, 'tripType', ''),
            }}
          />
        </span>
      </div>
      <div>
        <Anchor />
        <span>{formatHumanReadableList(packageData.sailingList?.[0]?.ports.map(({ name }) => name))}</span>
      </div>
    </div>
  ),
);
PackageDescription.displayName = 'PackageDescription';

const format = 'MMMM yyyy';
export const DatesLabel = ({ alignCenter = false, packageData }: { alignCenter?: boolean; packageData: Package }) => {
  const { sailingList } = packageData;
  const fromDate = formatDate(useAppSelector(selectFromDate), format);
  const toDate = formatDate(useAppSelector(selectToDate), format);

  return (
    <div className={cn(styles.totalSailingsCount, alignCenter && styles.alignCenter)}>
      <UIResource
        id="DatesModule.SailingSummary.label"
        values={{
          N: sailingList?.length,
          startDateEndDate: (
            <span className={styles.startDateEndDate}>
              {fromDate}
              {' - '}
              {toDate}
            </span>
          ),
        }}
      />
    </div>
  );
};
