'use client';

import { useCallback } from 'react';

import cn from 'classnames';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import AlmostSoldOut from '@/components/AlmostSoldOut';
import * as Drawer from '@/components/elements/Drawer';

import { DatesLabel, Header, HeaderWithImage, PackageDescription } from './components';
import SailingCards from './SailingCards/SailingCards';

import styles from './ViewMoreDates.module.scss';

type TViewMoreDatesProps = {
  onItemClick?: (sailing: SailingFromPackages) => void;
  packageData: FilteredPackage;
  withDescription?: boolean;
};

export const ViewMoreDates = ({ onItemClick, packageData, withDescription }: TViewMoreDatesProps) => (
  <div className={cn(styles.root, styles.withBorder)}>
    <Header />
    {withDescription && <PackageDescription packageData={packageData} />}
    <DatesLabel alignCenter packageData={packageData} />
    <AlmostSoldOut
      classificationCodes={packageData.classificationCodes}
      className={styles.almostSoldOut}
      messageId="AlmostSoldOut.PackageLevel.message"
      withoutStyles
    />
    <SailingCards onItemClick={onItemClick} packageData={packageData} />
  </div>
);

type TViewMoreDatesFlyoutProps = React.ComponentProps<typeof Drawer.Root> & {
  children?: React.ReactNode;
  ctaSlot?: React.ReactNode;
  onItemClick?: (sailing: SailingFromPackages) => void;
  packageData: FilteredPackage;
};

export const ViewMoreDatesFlyout = ({
  children,
  ctaSlot,
  onItemClick,
  onOpenChange,
  packageData,
  ...props
}: TViewMoreDatesFlyoutProps) => {
  const preventDefault = useCallback((event: Event) => event.preventDefault(), []);

  return (
    <Drawer.Root direction="right" onOpenChange={onOpenChange} {...props}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}
      <Drawer.Content
        className={styles.flyoutContent}
        onCloseAutoFocus={!children ? preventDefault : undefined}
        role="document"
        title="view more dates"
      >
        <Drawer.Title asChild>
          <HeaderWithImage packageData={packageData} />
        </Drawer.Title>
        <Drawer.Description asChild>
          <PackageDescription packageData={packageData} />
        </Drawer.Description>
        {ctaSlot && <div className={styles.ctaSlot}>{ctaSlot}</div>}
        <DatesLabel packageData={packageData} />
        <AlmostSoldOut
          classificationCodes={packageData.classificationCodes}
          className={styles.almostSoldOut}
          messageId="AlmostSoldOut.PackageLevel.message"
          withoutStyles
        />
        <SailingCards onItemClick={onItemClick} packageData={packageData} />
      </Drawer.Content>
    </Drawer.Root>
  );
};
