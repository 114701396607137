import { useQuery } from '@apollo/client';

import { GET_SPECIAL_SAILINGS } from '@/helpers/api/graphql';
import { InterruptionError } from '@/helpers/api/utils';
import { useAsyncError } from '@/hooks/useAsyncError';
import { type TImage } from '@/infra/types/common';

type SpecialSailings = {
  description: string | string[];
  id: string;
  mobileModalHero: null | TImage;
  modalDescription: null | string;
  name: string;
  portraitCarousel: TImage[];
  priority: number;
  ribbonColorOverride: null | string;
  showRibbon: boolean;
  subtitle: string;
};

export const useGetSpecialSailings = (): SpecialSailings[] => {
  const { data, error, errors, loading } = useQuery<{ lookup: { specialSailings: SpecialSailings[] } }>(
    GET_SPECIAL_SAILINGS,
  );
  const throwError = useAsyncError();

  const err = errors?.length ? (errors[0] as Error) : error;
  if (err && !InterruptionError.isInterruptionError(err)) throwError(err);

  if (loading) return [];

  return data?.lookup?.specialSailings || [];
};
