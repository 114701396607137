import { type HTMLAttributes, type MutableRefObject, useCallback } from 'react';

import cn from 'classnames';

import type { TKeyEventGroup } from '@/ducks/a11y/types';
import type { TBoxSide, TOptional, TSideAlign } from '@/types/common';

import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';
import { useModalStateFocus } from '@/ducks/a11y/hooks/useModalStateFocus';
import useClickOutside from '@/hooks/useClickOutside';

import usePositionVars, { type TOnRealign, type TRealign } from './hooks/usePositionVars';

import './ContextModal.scss';

export type { TOnRealign, TRealign };

export const CSS_VARS = {
  x: 'context-modal-x',
  y: 'context-modal-y',
} as const;

export const FOCUS_TRAP_DISABLE: TKeyEventGroup[] = ['escape', 'tab', 'x-arrows', 'y-arrows'];

type TProps = HTMLAttributes<HTMLDivElement> & {
  align?: TOptional<TSideAlign>;
  anchorRef: MutableRefObject<HTMLElement | null>;
  children?: React.ReactNode;
  className?: string;
  disableTrapEvents?: TKeyEventGroup[];
  includeFirstOutsideClick?: boolean;
  isOpened?: boolean;
  onClose: (byKeyboard?: boolean) => void;
  onRealign?: TOptional<TOnRealign>;
  side?: TOptional<TBoxSide>;
};

const ContextModal = ({
  align = 'center',
  anchorRef,
  children,
  className,
  disableTrapEvents,
  includeFirstOutsideClick = true,
  isOpened,
  onClose,
  onRealign,
  side = 'bottom',
  ...rest
}: TProps) => {
  const ref = useClickOutside<HTMLDivElement>(onClose, !isOpened, includeFirstOutsideClick);
  const focusTrapDisable = disableTrapEvents?.length
    ? FOCUS_TRAP_DISABLE.filter((i) => !disableTrapEvents.includes(i))
    : FOCUS_TRAP_DISABLE;

  const onEscape = useCallback(() => onClose?.(true), [onClose]);

  useFocusTrap({
    disableEvents: disableTrapEvents,
    element: ref.current!,
    isOpened,
    onClose: onEscape,
  });
  usePositionVars({
    align,
    anchorRef,
    cssVarNames: CSS_VARS,
    disabled: !isOpened,
    onRealign,
    side,
    targetRef: ref,
  });
  useModalStateFocus({
    isOpen: isOpened!,
    rootElement: ref.current!,
  });

  return (
    <div
      {...rest}
      className={cn('ContextModal', className, `_${side}`, `_${align}`, { _opened: isOpened })}
      data-focus-trap-disable={focusTrapDisable.join(',')}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default ContextModal;
