import { Fragment, useMemo } from 'react';

import sortBy from 'lodash/sortBy';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import UIResource from '@/components/UIResource';
import UIResourceHtml from '@/components/UIResource/Html';
import { selectCabins, selectPriceType, selectSailors, selectSortType } from '@/ducks/filters/selectors';
import { getPortsFromDefaultPackOfGrouped } from '@/ducks/pages/chooseVoyage/groupPackagesWithSameNames';
import { makePackageSailingSorters } from '@/ducks/pages/chooseVoyage/sailingSorters';
import { getBestPriceSailing } from '@/ducks/pages/chooseVoyage/utility';
import { useGetIsTaxIncluded } from '@/helpers/pricing/lib/tax';
import { formatDate } from '@/helpers/util/dateUtil';
import { FiltersPriceType, FiltersSortType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import PortMessage, { PORT_MESSAGE_FORMATS } from '../../PortMessage';
import { PricedSailingCard } from '../PricedSailingCard';

import styles from './SailingCards.module.scss';

const getSailingMonthLabel = (sailings: Array<{ startDate: string }>, index: number) => {
  const sailing = sailings[index];
  if (sailing == null) return;

  return formatDate(sailing.startDate, 'MMMM yyyy');
};

const getSortOptionName = (sortType: string) => {
  if (sortType === FiltersSortType.priceAsc) {
    return <UIResource id="ItineraryOverlay.priceSortType.low.text" />;
  }
  if (sortType === FiltersSortType.priceDesc) {
    return <UIResource id="ItineraryOverlay.priceSortType.high.text" />;
  }
};

const getPricePerText = (priceType: string) => {
  if (priceType === FiltersPriceType.perCabin) {
    return <UIResource id="PriceFootnote.flyout.perCabin" />;
  }
  if (priceType === FiltersPriceType.sailorPerNight) {
    return <UIResource id="PriceFootNote.cabinModule.perSailorPerNight" />;
  }
  return <UIResource id="PriceFootnote.flyout.perSailor" />;
};

const SailingCards = ({
  onItemClick,
  packageData,
}: {
  onItemClick?: (sailing: SailingFromPackages) => void;
  packageData: FilteredPackage;
}) => {
  const isTaxIncluded = useGetIsTaxIncluded();

  const cabins = useAppSelector(selectCabins);
  const sailors = useAppSelector(selectSailors);
  const sortType = useAppSelector(selectSortType);
  const priceType = useAppSelector(selectPriceType);

  const sailingList = useMemo(
    () => sortBy(packageData.sailingList, ...makePackageSailingSorters(sortType, priceType, sailors)),
    [packageData, sortType],
  );
  const sailingsSortedByPrice = sortType === FiltersSortType.priceAsc || sortType === FiltersSortType.priceDesc;

  const bestPriceSailing = useMemo(() => getBestPriceSailing(packageData.sailingList), [packageData]);
  const bestRateShouldShown = packageData.bestRate && sailingList.length > 1;

  const isPortChange = packageData.isPortChangeAvailable;

  return (
    <div>
      {isPortChange && <PortMessage format={PORT_MESSAGE_FORMATS.FLYOUT} />}

      {bestRateShouldShown && bestPriceSailing && (
        <>
          <UIResourceHtml
            className={styles.bestRateHeading}
            id="DatesModule.BestRate.label"
            values={{
              'Month Year': formatDate(bestPriceSailing?.startDate, 'MMMM yyyy'),
            }}
          />
          <PricedSailingCard
            cabins={cabins}
            defaultPackagePorts={getPortsFromDefaultPackOfGrouped(packageData)}
            isPortChangeAvailable={isPortChange}
            onClick={onItemClick}
            priceType={priceType}
            sailing={bestPriceSailing}
            sailors={sailors}
          />
        </>
      )}

      <div>
        {sailingsSortedByPrice && (
          <>
            <div className={styles.monthTitle}>{getSortOptionName(sortType)}</div>
            {sailingList?.map((sailingData) => (
              <Fragment key={sailingData.id}>
                <PricedSailingCard
                  cabins={cabins}
                  defaultPackagePorts={getPortsFromDefaultPackOfGrouped(packageData)}
                  isPortChangeAvailable={isPortChange}
                  onClick={onItemClick}
                  priceType={priceType}
                  sailing={sailingData}
                  sailors={sailors}
                />
                <div aria-hidden className={styles.cardSeparator} />
              </Fragment>
            ))}
          </>
        )}
        {!sailingsSortedByPrice &&
          sailingList?.map((sailingData, index) => {
            const prevSailingMonth = getSailingMonthLabel(sailingList, index - 1);
            const sailingMonth = getSailingMonthLabel(sailingList, index);
            const nextSailingMonth = getSailingMonthLabel(sailingList, index + 1);
            const isNewMonth = prevSailingMonth !== sailingMonth;
            const isNextTimeNewMonth = sailingMonth !== nextSailingMonth;

            return (
              <Fragment key={sailingData.id}>
                {isNewMonth && <div className={styles.monthTitle}>{sailingMonth}</div>}
                <PricedSailingCard
                  cabins={cabins}
                  defaultPackagePorts={sailingList?.[0]?.ports ?? []}
                  isPortChangeAvailable={isPortChange}
                  onClick={onItemClick}
                  priceType={priceType}
                  sailing={sailingData}
                  sailors={sailors}
                />
                {!isNextTimeNewMonth && <div aria-hidden className={styles.cardSeparator} />}
              </Fragment>
            );
          })}
      </div>

      <div className={styles.averagePriceText} suppressHydrationWarning>
        <UIResource
          id="NextSailingFlyout.AveragePricePer.text"
          values={{
            noOfsailors: sailors,
            pricePer: getPricePerText(priceType),
            taxText: <UIResource id={`MainNav.Summary.tax.${isTaxIncluded ? 'including' : 'excluding'}.mobile`} />,
          }}
        />
      </div>
    </div>
  );
};

export default SailingCards;
